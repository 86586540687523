import "normalize.css";
import "./styles.less";

const images = {
  desktop: [...document.querySelectorAll(".desktop .img-container")],
  tablet: [...document.querySelectorAll(".tablet .img-container")],
  mobile: [...document.querySelectorAll(".mobile .img-container")],
};

const desktopMedia = "screen and (min-width: 1600px)";
const tabletMedia = "screen and (min-width: 800px)";

function getCurrentImages() {
  if (window.matchMedia(desktopMedia).matches) {
    return images.desktop;
  } else if (window.matchMedia(tabletMedia).matches) {
    return images.tablet;
  }
  return images.mobile;
}

class Scroller {
  constructor() {
    this.images = getCurrentImages();

    window.addEventListener('resize', () => {
      this.images = getCurrentImages();
    }, true);
  }

  scrollTo(index) {
    /**
     * @type {HTMLElement}
     */
    let target = this.images[0];

    if (index >= 0 && index < this.images.length) {
      target = this.images[index];
    }

    window.scrollTo({
      behavior: "smooth",
      top: target.offsetTop,
    });
  }

  findIndex(fromNode) {
    for (let index = 0; index < this.images.length; index++) {
      const image = this.images[index];
      if (image.contains(fromNode)) {
        return index;
      }
    }
  }

  scrollToNext(fromNode) {
    const currentIndex = this.findIndex(fromNode);
    this.scrollTo(currentIndex + 1);
  }

  toggleNext(fromNode) {
    const currentIndex = this.findIndex(fromNode);
    const next = this.images[currentIndex + 1];
    this.scrollTo(currentIndex + 1);
    if (next.classList.contains("is-closed")) {
      next.classList.remove("is-closed");
    } else {
      next.classList.add("is-closed");
    }
  }

  close(fromNode) {
    const currentIndex = this.findIndex(fromNode);
    const img = this.images[currentIndex];
    this.scrollTo(currentIndex);
    img.classList.add('is-closed');
  }
}

const scroller = new Scroller();

const handler = {
  DOWN: (evt) => {
    scroller.scrollToNext(evt.target);
  },
  HOME: () => {
    scroller.scrollTo(0);
  },
  SERVICE: () => {
    scroller.scrollTo(1);
  },
  HARDWARE: () => {
    scroller.scrollTo(3);
  },
  SOFTWARE: () => {
    scroller.scrollTo(5);
  },
  VISUALISIERUNG: () => {
    scroller.scrollTo(7);
  },
  KONTAKT: () => {
    scroller.scrollTo(9);
  },
  MORE: (evt) => {
    scroller.toggleNext(evt.target);
  },
  CLOSE: (evt) => {
    scroller.close(evt.target);
  },
};

[...document.querySelectorAll("#HOME, #TOP")].forEach((link) =>
  link.addEventListener("click", handler.HOME)
);
[...document.querySelectorAll("#SERVICE")].forEach((link) =>
  link.addEventListener("click", handler.SERVICE)
);
[...document.querySelectorAll("#HARDWARE")].forEach((link) =>
  link.addEventListener("click", handler.HARDWARE)
);
[...document.querySelectorAll("#SOFTWARE")].forEach((link) =>
  link.addEventListener("click", handler.SOFTWARE)
);
[...document.querySelectorAll("#VISUALISIERUNG")].forEach((link) =>
  link.addEventListener("click", handler["VISUALISIERUNG"])
);
[...document.querySelectorAll("#KONTAKT, #KONTAKT_BUTTON")].forEach((link) =>
  link.addEventListener("click", handler.KONTAKT)
);
[...document.querySelectorAll("#DOWN")].forEach((link) =>
  link.addEventListener("click", handler.DOWN)
);
[...document.querySelectorAll("#MORE")].forEach((link) =>
  link.addEventListener("click", handler.MORE)
);
[...document.querySelectorAll("#CLOSE")].forEach((link) =>
  link.addEventListener("click", handler.CLOSE)
);

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

if (
  getUrlParameter("debug") === "1" &&
  document.querySelector("body").style.setProperty
) {
  document.querySelector("body").style.setProperty("--fill-opacity", 0.2);
}
